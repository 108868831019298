import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box, ResponsiveContext } from 'grommet';
import SectionHeader from '../SectionHeader';
import ComplianceFeature from './ComplianceFeature';

const StyledSectionHeader = styled(SectionHeader)`
  ${({ alignLeft }) => (alignLeft ? 'text-align: left !important;' : '')}
`;

export default function({ headline, body, features, backgroundImage, image }) {
  return (
    <Box
      id="compliance"
      pad={{ vertical: 'xlarge' }}
      background={{
        color: 'dark-2',
        position: 'top left',
        size: 'contain',
        image: `url(${backgroundImage})`,
      }}
    >
      <ResponsiveContext.Consumer>
        {(size) =>
          (headline || body) && (
            <StyledSectionHeader headline={headline} body={body} alignLeft={size === 'small'} />
          )
        }
      </ResponsiveContext.Consumer>
      <Box direction="row-responsive" fill="horizontal" margin={{ bottom: 'large' }} wrap={false}>
        <Box basis="1/2" align="center" justify="center" pad={{ horizontal: 'medium' }}>
          {features && features.map((feature) => <ComplianceFeature {...feature} />)}
        </Box>
        <Box basis="1/2" fill="horizontal" justify="center" pad={{ left: 'large' }}>
          {image && <Img fluid={image.localFile.childImageSharp.fluid} />}
        </Box>
      </Box>
    </Box>
  );
}
