import React from 'react';
import { Box, Heading, Image, Text, ThemeContext } from 'grommet';
import RichText from '../RichText';
import GatsbyAnchor from '../GatsbyAnchor';

export default function({ body, image, gtmText, gtmUrl, headline, ...rest }) {
  return (
    <>
    {gtmUrl && (
      <GatsbyAnchor
        href={gtmUrl}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          marginTop: '20px'
        }}
      >
        <Box align="center" alignContent="center" style={{ textAlign: 'center' }} {...rest}>
          <Box height="80px" width="80px">
            {image && <Image src={image} fit="contain" />}
          </Box>
          {headline && (
            <Heading margin={{ bottom: 'none', top: '20px' }} level="4">
              {headline}
            </Heading>
          )}
          {body && (
            <ThemeContext.Extend value={{ paragraph: { extend: () => `margin-top: 0` } }}>
              <RichText body={body} />
            </ThemeContext.Extend>
          )}
        </Box>
          <Box>{gtmText && <Text style={{textAlign: 'center', color: '#EA8C00'}}>{gtmText}</Text>}</Box>
      </GatsbyAnchor>

    )}
    </>
  );
}
