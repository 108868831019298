import React from 'react';
import { Box, Paragraph, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';
//import RichText from '../RichText';

const HeroContainer = styled(Box)`
  min-height: 66.667vh;
  position: relative;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 50vh;' : 'position: absolute !important; z-index: -1;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};

  @media (max-height: 640px) {
    max-height: 100vh;
  }
`;

const BgHeading = styled(Heading)`
  font-family: PwC Helvetica Neue, sans-serif;
  font-weight: 600;
  margin: 0;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 20px !important;
  vertical-align: top;
  background: #2d2d2d;
  box-shadow: 10px 0 0 #2d2d2d, -10px 0 0 #2d2d2d;
  color: white;
  display: inline !important;
  padding: 0;
  box-decoration-break: clone;
`

const BgSubtitle = styled.span`
  font-family: PwC Helvetica Neue, sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  line-height: 2.2;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 20px !important;
  vertical-align: top;
  background: #2d2d2d;
  box-shadow: 10px 0 0 #2d2d2d, -10px 0 0 #2d2d2d;
  color: white;
  display: inline !important;
  padding: 0;
  box-decoration-break: clone;
`


export default function({ Link, headline, subtitle, image, buttonText, buttonUrl, background }) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1200 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroContainer align="end" direction="row-responsive">
              {BgImg && (
                <BgImg
                  small={small}
                  objectFit="cover"
                  objectPosition={small ? 'center right' : 'center center'}
                  imgStyle={{
                    right: 0,
                    left: 'auto',
                    objectFit: 'cover',
                    objectPosition: small ? 'center right' : 'center center',
                    width: small ? '50%' : '100%',
                  }}
                  fluid={image.localFile.childImageSharp.fluid}
                />
              )}
              <Box
                align="start"
                basis="2/3"
                pad={small ? 'large' : 'large'}
                justify="start"
                background={small ? '#2D2D2D' : '' }
              >
                {headline && (
                  <BgHeading level="1">
                    {headline}
                  </BgHeading>
                )}
                {subtitle && (
                  <Paragraph
                    size="large"
                    margin="0 0 40px 0">
                    <BgSubtitle>{subtitle}</BgSubtitle>
                  </Paragraph>
                )}
                {buttonUrl && buttonText && (
                  <GatsbyButton Link={Link} href={buttonUrl} label={buttonText} primary margin="0 0 20px 0" />
                )}
              </Box>
            </HeroContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
