import React, { useState } from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import CtaBanner from '../components/CtaBanner';
import styled from 'styled-components';
import HeroInteriorAlt2 from '../components/HeroInteriorAlt2';
import StoryListAlt from '../components/StoryListAlt';
import GatsbyAnchor from '../components/GatsbyAnchor';

const FeatContainer = styled(Box)`
  background: white;
  border: solid 1px #D2D2D2;
  border-radius: 5px;
  // width: calc(calc(32% - 16px));
  padding: 48px 24px;
  text-align: center;
  margin-bottom: 20px;
`;
const FeatContainerLink = styled(GatsbyAnchor)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  outline-color: none !important;
  border-color: none;
`;

export default function({ doc, Link }) {
  const [stateTags, setTags] = useState({ 'All Content': true });
  if (!doc) return null;

  const { hero, categories, cta } = doc;
  const handleTag = (e, tagValue) => {
    const target = e.target;
    setTags((tags) => {
      return {
        ...tags,
        [tagValue]: target.checked
      }
    })
  };
  const pad = {
    small: 'large',
    hero: 'medium',
  };

  return (
    <>
      <HeroInteriorAlt2 Link={Link} {...hero} />
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = size === 'small';
          const hero = size === 'hero';

          return (
            <Box
              pad={{top: 'medium'}}
            >
              <Heading
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                Featured Insights
              </Heading>
              <Box 
                direction="row-responsive"
                justify="between"
                wrap 
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
                pad={small || hero ? pad[size] : 'large'}
              >
                <FeatContainer
                  basis={small ? 'auto' : 'calc(32% - 16px)'}
                >
                  <FeatContainerLink href={"https://offer.taxverse.pwc.com/guide-for-collecting-sales-tax?utm_source=insights-center"} target={"_blank"}>
                    <img src="https://images.ctfassets.net/gzpz2xecclqo/30ucYHeSuYpcVqXVxaA8R6/a2571fbbc308737309dac2ae190a04af/tv-ebook.svg" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >eBook: Guide to collecting sales tax
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Understand the basics of a modern sales tax strategy and ensure your business isn’t caught off-guard.</Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"/exposure-calculator?utm_source=insights-center"}>
                    <img src="https://images.ctfassets.net/gzpz2xecclqo/5Zs9y9lfM2K3kHmbUKWpIu/0fdc6451b6b4a1c26345b3cc741029b6/tv-calc.svg" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Sales tax risk exposure calculator
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    ><i>How costly could it be if you don’t comply with sales tax rules?</i> Get an estimate of your potential sales tax exposure.</Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"/fastcast?utm_source=insights-center"}>
                    <img src="https://images.ctfassets.net/gzpz2xecclqo/3gHH3kKWxyJg0RRJ1lbbXd/fb04a4c298cf28151644c7c52820e6a2/tv-fastcast.svg" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >On-demand webcast series about sales tax
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Level up and gain confidence in how you deal with sales taxes by going through our <i>Sales Tax Webcast</i> series.</Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
              </Box>
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
      {categories &&
        categories.map((stories, index) => {
          const cardsFiltered = stories.cards.filter((card) => {
            const shouldShow = stateTags['All Content']
              ? true
              : card.tags === null
              ? false
              : card.tags &&
                card.tags.reduce((acc, curTag) => {
                  return acc || stateTags[curTag] || false;
                }, false);
            return shouldShow;
          });
          const storiesFiltered = { ...stories, cards: cardsFiltered };

          console.log(cardsFiltered);
          const background = (index + 1) % 2 === 0 ? 'light-6' : 'white';
          return (
            <>
              <Box 
                pad={{top: 'large'}}
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
              >
              <Heading
                margin={{ top: 'medium', bottom: 'medium' }}
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                Webcasts, eBooks, and Blog Posts
              </Heading>
              </Box>
  
              <Box 
                pad={{bottom: 'xlarge'}}
                direction="row-responsive"
                justify="evenly"
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
              >
                <Box 
                  direction="row"
                  justify="between"
                >
                <StoryListAlt Link={Link} background={background} {...storiesFiltered} />
                </Box>
              </Box>
              {cta && cta.headline && <CtaBanner Link={Link} background={background} {...cta} />}
            </>
          );
        })}
    </>
  );
}
