import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Image, ResponsiveContext, Paragraph} from 'grommet';
const WIFeaturedContainer = styled(Box)`
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  padding: 30px;
`;
const IconImage = styled(Image)`
  max-width: 60px;
  padding-bottom: 10px;
`;
const WIFeaturedHeadline = styled(Heading)`
  text-align: left;
  margin: 10px 0 0;
`;
const WIAddOnContainer = styled(Box)`
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  padding: 30px;
  display: flex;
`;
const WIAddOnHeadline = styled(Heading)`
  text-align: left;
  margin: 10px 0 0;
`;
const WIParagraph = styled(Paragraph)`
  margin-top: 10px;
  font-size: 17px;
  line-height: 28px;
  text-align: left;
`;
export default function() {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = ['small', 'hero'].includes(size);

        return (
          <Box
            id="whatsIncluded"
            pad={
              small
                ? { horizontal: 'large', bottom: 'large' }
                : { horizontal: 'xlarge', bottom: 'xlarge' }
            }
            style={{ background: `url('https://images.ctfassets.net/l07wyut35yzl/3CSaA8AH1fEAvrzXlccEuQ/1d840454b2b37ebf624759d913866ae5/pricing-pattern-bg.svg') top center` }}
          >
            <Box
              align="center"
              pad={size === 'small' ? 'large' : 'xlarge'}
              style={{ textAlign: 'center' }}
            >
              <Heading level="2" margin="none">
                Affordable fixed monthly pricing
              </Heading>
            </Box>
            <Box
              align="center"
              style={{ textAlign: 'center', marginBottom: '20px' }}
            >
              <Heading level="3" margin="small">
                Connected Bookkeeping
              </Heading>
            </Box>
            <Box
                direction={small ? 'column' : 'row'}
                gap="large"
                alignSelf="center"
                alignContent="center"
                margin={{ bottom: 'large' }}
                width={{
                  "max": "1320px"
                }}
              >
                <WIFeaturedContainer>
                <IconImage src="https://images.ctfassets.net/l07wyut35yzl/BzRrIr9S1MkPBqxhFYP0u/444daa5e56f636f69dd4c9420cf02aef/insights-officer-yellow-work-smarter-picto.svg" />
                  <WIFeaturedHeadline level="4">Smarter bookkeeping</WIFeaturedHeadline>
                  <WIParagraph>Transactions recorded daily and reconciliations performed monthly.</WIParagraph>
                </WIFeaturedContainer>
                <WIFeaturedContainer>
                  <IconImage src="https://images.ctfassets.net/l07wyut35yzl/4THrHFaH7Rjns3manfmIwe/65f5997bca01ede8b3235736262e024c/insights-officer-yellow-cash-flow-picto.svg" />
                  <WIFeaturedHeadline level="4">Intelligent bill pay</WIFeaturedHeadline>
                  <WIParagraph>Automation reduces your time and effort paying vendors.</WIParagraph>
                </WIFeaturedContainer>       
            </Box>
            <Box
                direction={small ? 'column' : 'row'}
                alignSelf="center"
                alignContent="center"
                margin={{ bottom: 'xlarge' }}
                style={{maxWidth: '600px', width: '100%'}}
              >
                <WIFeaturedContainer style={{margin: '0 auto',  width: '100%'}}>
                  <IconImage src="https://images.ctfassets.net/l07wyut35yzl/DAmS3d9ITn2iidUdmRGr4/534dc1a9d145a8f1379785f2055828b1/insights-officer-yellow-actionable-insights-picto.svg" />
                  <WIFeaturedHeadline level="4">Actionable reporting</WIFeaturedHeadline>
                  <WIParagraph style={{maxWidth: '540px'}}>Monthly profit and loss statements, balance sheets, accounts receivable aging and accounts payable aging. Access to our standard financial dashboards.</WIParagraph>
                </WIFeaturedContainer>
              </Box>
            <Box
              align="center"
              style={{ textAlign: 'center', margin: '40px 0 20px' }}
            >
              <Heading level="3">Add-On Features</Heading>
            </Box>
            <Box
                direction={small ? 'column' : 'row'}
                gap="large"
                alignSelf="center"
                alignContent="center"
                margin={{ bottom: 'large' }}
                width={{
                  "max": "1320px"
                }}
              >
                <WIAddOnContainer>
                  <WIAddOnHeadline level="4">Customized internal reporting</WIAddOnHeadline>
                  <WIParagraph>Expanded internal reporting to meet your business needs. In-depth analysis to understand the drivers of your business.</WIParagraph>
                </WIAddOnContainer>
                <WIAddOnContainer>
                  <WIAddOnHeadline level="4">Budgeting support</WIAddOnHeadline>
                  <WIParagraph>Annual budget creation support and tracking. Real-time comparison of budget to actuals and period-over-period variances. Periodic revisions to reflect market shifts.</WIParagraph>
                </WIAddOnContainer>
            </Box>
            <Box
                direction={small ? 'column' : 'row'}
                gap="large"
                alignSelf="center"
                alignContent="center"
                margin={{ bottom: 'xlarge' }}
                width={{
                  "max": "1320px"
                }}
              >
                <WIAddOnContainer>
                  <WIAddOnHeadline level="4">Business process reviews</WIAddOnHeadline>
                  <WIParagraph>In-depth review and recommendations for your back office processes. Design, documentation,  and implementation of your accounting policies, procedures and controls.</WIParagraph>
                </WIAddOnContainer>
                <WIAddOnContainer>
                  <WIAddOnHeadline level="4">Tax compliance</WIAddOnHeadline>
                  <WIParagraph>State and federal tax filing and compliance performed by PwC tax professionals.</WIParagraph>
                </WIAddOnContainer>
            </Box>
            <Box
              align="center"
              style={{ textAlign: 'center' }}
            >
              <Paragraph style={{maxWidth: '600px', fontWeight: 'bold'}}>And &mdash; as always &mdash; you have access to the suite of services offered by PwC Private. PwC has more than 2,000 audit, tax and advisory professionals solely focused on private companies like yours.</Paragraph>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
