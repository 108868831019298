import React from 'react';
import CtaBanner from '../components/CtaBanner';
import WhatsIncludedBC from '../components/WhatsIncludedBC';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { pricing, cta } = doc;
  return (
    <>
      <WhatsIncludedBC Link={Link} {...pricing} />
      <CtaBanner Link={Link} {...cta} />
    </>
  );
}
