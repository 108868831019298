import React from 'react';
import styled from 'styled-components';
import { Anchor, Box, Button, Heading, Image, Paragraph, ResponsiveContext } from 'grommet';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from 'grommet-icons';
import GatsbyAnchor from '../GatsbyAnchor';
import GatsbyButton from '../GatsbyButton';

const Icons = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  youtube: Youtube,
};

const FooterCopyright = styled(Paragraph)`
  @media (min-width: 1024px) {
    padding-right: 80px;
    padding-top: 20px;
  }
`;
const FooterContainer = styled(Box)`
  @media (min-width: 1024px) {
    text-align: left;
  }
  text-align: center;
`;

const FooterLink = styled(GatsbyAnchor)`
  line-height: 1.2;
  margin-bottom: 1rem;
`;
 
export default function({
  Link,
  phone,
  logoUrl,
  buttonColor,
  column1Header,
  column2Header,
  column3Header,
  column3Links,
  headerColor,
  socialLinks,
  primaryLink,
  menuLinks,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const mobile = size === 'small';
        const hero = size === 'hero';
        const align = mobile || hero ? 'center' : 'start';
        const pad = {
          vertical: mobile || hero ? 'medium' : 'none',
        };

        return (
          <Box background="dark-2" pad={{ horizontal: 'large', top: 'xlarge', bottom: 'large' }}>
            <FooterContainer direction="row" align="start" justify="between" wrap>
              <Box basis={mobile || hero ? 'full' : '40%'} align={align} justify="start" pad={pad}>
                {logoUrl && (
                  <GatsbyAnchor Link={Link} href="/">
                    <Box pad={{ vertical: 'small' }}>
                      <Image src={logoUrl} height="52px" />
                    </Box>
                  </GatsbyAnchor>
                )}
                <Anchor href="https://www.pwc.com/us/en/products.html" target="_blank" size="small" color="white">
                    PwC Products
                </Anchor>
                <FooterCopyright>
                  <Paragraph size="xsmall" textAlign={mobile ? 'center' : 'start'} pad="medium">
                  <i>© {new Date().getFullYear()} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <Anchor color="#FFFFFF" textDecoration="none" href="https://www.pwc.com/structure">www.pwc.com/structure</Anchor> for further details.</i>
                  </Paragraph>
                </FooterCopyright>
              </Box>
              <Box basis={mobile ? 'full' : '20%'} align={align} justify="start" pad={pad}>
                {column1Header && (
                  <Heading
                    level="4"
                    margin={{ top: 'none' }}
                    color={headerColor || 'brand'}
                    size="small"
                    uppercase
                  >
                    {column1Header}
                  </Heading>
                )}
                {primaryLink && (
                  <GatsbyButton
                    Link={Link}
                    color={buttonColor || 'brand'}
                    fill={mobile}
                    href={primaryLink.url}
                    label={primaryLink.name}
                  />
                )}
                {phone && (
                  <Paragraph
                    level="3"
                    margin={{ bottom: 'none' }}
                    color={headerColor || 'brand'}
                  >
                    Call us: {phone}
                  </Paragraph>
                )}
                <Box direction="row" margin={{ vertical: 'medium' }} gap="medium">
                  {socialLinks &&
                    socialLinks.length &&
                    socialLinks.map(({ icon, name, url }) => {
                      const Icon = Icons[icon.toLowerCase()];

                      return (
                        Icon &&
                        url && (
                          <Button
                            a11yTitle={name}
                            href={url}
                            icon={<Icon color="white" size="32px" />}
                            target="_blank"
                            rel="noopener noreferrer"
                            plain
                          />
                        )
                      );
                    })}
                </Box>
              </Box>
              <Box basis={mobile ? '1/2' : '20%'} align={align} justify="start" pad={pad}>
                {column2Header && (
                  <Heading
                    level="4"
                    margin={{ top: 'none' }}
                    color={headerColor || 'brand'}
                    size="small"
                    uppercase
                  >
                    {column2Header}
                  </Heading>
                )}
                {menuLinks &&
                  menuLinks.map(({ name, url }) => {
                    return (
                      url &&
                      name && (
                        <FooterLink Link={Link} href={url} size="small" color="white">
                          {name}
                        </FooterLink>
                      )
                    );
                  })}
              </Box>

              <Box basis={mobile ? '1/2' : '20%'} align={align} justify="start" pad={pad}>
                {column3Header && (
                  <Heading
                    level="4"
                    margin={{ top: 'none' }}
                    color={headerColor || 'brand'}
                    size="small"
                    uppercase
                  >
                    {column3Header}
                  </Heading>
                )}
                {column3Links &&
                  column3Links.map(({ name, url }) => {
                    return (
                      url &&
                      name && (
                        <FooterLink Link={Link} href={url} size="small" color="white" >
                          {name}
                        </FooterLink>
                      )
                    );
                  })}
              </Box>
            </FooterContainer>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
