import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box, ResponsiveContext } from 'grommet';
import SectionHeader from '../SectionHeader';
import GetStartedFeature from './GetStarttedFeature';

const StyledSectionHeader = styled(SectionHeader)`
  ${({ alignLeft }) => (alignLeft ? 'text-align: left !important;' : '')}
`;

export default function({ headline, body, features, backgroundImage, image }) {
  return (
    <Box
      id="compliance"
      pad={{ top: '0', bottom: 'large' }}
      background={{
        color: 'light-1',
        position: 'top left',
        size: 'contain',
        image: `url(${backgroundImage})`,
      }}
    >
      <ResponsiveContext.Consumer>
        {(size) =>
          (headline || body) && (
            <StyledSectionHeader headline={headline} body={body} alignLeft={size === 'small'} />
          )
        }
      </ResponsiveContext.Consumer>
      <Box 
        direction="row-responsive" 
        fill="horizontal" 
        margin={{ bottom: 'large' }} 
        wrap={false}
        style={{
          maxWidth: '1420px',
          margin: '0 auto',
          width: '100%'
        }}
        >
        <Box basis="1/3"  justify="center" pad={{ horizontal: 'medium' }}>
          {features && features.map((feature) => <GetStartedFeature {...feature} />)}
        </Box>
        <Box basis="2/3" fill="horizontal" justify="center" pad={{ left: 'large' }}>
          {image && <Img fluid={image.localFile.childImageSharp.fluid} />}
        </Box>
      </Box>
    </Box>
  );
}
