import React from 'react';
import styled from 'styled-components';
import { Box, Button, Menu } from 'grommet';
import HeaderAltNavAnchor from './HeaderAltNavAnchor';
import GatsbyButton from '../GatsbyButton';

const DropdownBox = styled(Box)`
align-self: center;
font-weight: normal;
// &:hover {
//   background-color: #d04A02;
//   color: var(--accent-2);
// }
`;

const MenuContainer = styled(Menu)`
padding: none;
&:hover {
  background-color: none;
  color: var(--accent-2);
}
`;

export default function({ Link, loginLink, menuLinks, mobile, primaryLink, href, ...rest }) {
  return (
    <>
      <Box
        as="nav"
        alignSelf="stretch"
        direction={mobile ? 'column' : 'row'}
        pad={mobile ? 'large' : null}
        {...rest}
      >
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/how-it-works'
          mobile={mobile}
          size={mobile ? 'large' : 'medium'}
          style={mobile ? '' : {marginRight: '10px', marginRight: '30px'}}
        > How it works
        </HeaderAltNavAnchor>
        <MenuContainer
            label="Solutions"
            dropProps={{ align: { top: "bottom", left: "left" }, margin: {top: '40px'}}}
            dropBackground={{"color": "#ffffff", "opacity": false}}
            style={{fontWeight: 'normal', marginRight: '10px'}}
            items={[
              { label: <DropdownBox>For Law Firms</DropdownBox>, href: '/for-law-firms' },
              { label: <DropdownBox>For Non Profits</DropdownBox>, href: '/for-non-profits' },
              { label: <DropdownBox>For Private Equity</DropdownBox>, href: '/for-private-equity' },
              { label: <DropdownBox>For Small Business</DropdownBox>, href: '/for-small-business' }
            ]}
          />
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/resources'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'large' : 'medium'}
          style={mobile ? '' : {marginLeft: '10px',marginRight: '30px'}}
          partiallyActive
        > Resources
        </HeaderAltNavAnchor>
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/whats-included'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'large' : 'medium'}
          partiallyActive
        > What's included
        </HeaderAltNavAnchor>
      </Box>
      
      <Box
        direction={mobile ? 'column' : 'row'}
        gap="small"
        pad={mobile ? { horizontal: 'medium', bottom: '120px' } : { vertical: 'small' }}
      >
        {loginLink && loginLink.name && loginLink.url && (
          <Button
            color="accent-2"
            label={loginLink.name}
            href={loginLink.url}
            rel="noopener noreferrer"
            target="_blank"
          />
        )}
        {primaryLink && primaryLink.name && primaryLink.url && (
          <GatsbyButton
            Link={Link}
            color="accent-2"
            label={primaryLink.name}
            href={primaryLink.url}
            primary
          />
        )}
      </Box>
    </>
  );
}
