import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const SocialBox = styled(Box)`
  ${({ isSmall }) => (isSmall ? 'height: 45px;' : 'height: 50px;')}
  ${({ isSmall }) => (isSmall ? 'width: 45px;' : 'width: 50px;')}
  border-radius: 45px;
  margin-bottom: 20px;
  ${({ background }) => `background-color: ${background}`}
`;

const setShareLink = (name, title) => {
  const url = window.location.href;
  let shareLink;

  switch (name) {
    case 'Twitter':
      shareLink = `https://twitter.com/intent/tweet/?text=${title}&url=${url}`;
      break;
    case 'Facebook':
      shareLink = `https://www.facebook.com/share.php?u=${url}&title=${title}`;
      break;
    case 'Linkedin':
      shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`;
      break;
    case 'Mail':
      shareLink = `mailto:?subject=${title}&body=${url}`;
      break;
    default:
      shareLink = null;
  }

  return encodeURI(shareLink);
};

export default function SocialCard({ name, icon, background, title }) {
  const shareLink = setShareLink(name, title);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small' || size === 'hero';

        return (
          <a id={name} href={shareLink} target="_blank" rel="noopener noreferrer">
            <SocialBox
              href={shareLink}
              background={background}
              isSmall={isSmall}
              justify="center"
              align="center"
            >
              {icon}
            </SocialBox>
          </a>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
