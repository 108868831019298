import React from 'react';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import RichText from '../RichText';

const HeroOuterContainer = styled(Box)`
  display: block;
  position: relative;
`;

const HeroContainer = styled(Box)`
  min-height: 30vh;
  position: relative;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 50vh;' : 'position: absolute !important; z-index: 0;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};

  @media (max-height: 640px) {
    max-height: 100vh;
  }
`;

export default function({
  Link,
  background,
  headline,
  body,
  image,
  textBackground,
}) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroOuterContainer background={background} pad={small ? null : { bottom: '72px' }}>
              <HeroContainer align="start" background="white" direction="row-responsive">
                {BgImg && (
                  <BgImg
                    small={small}
                    objectFit="cover"
                    objectPosition="center center"
                    imgStyle={{
                      right: 0,
                      left: 'auto',
                      objectFit: 'cover',
                      objectPosition: 'center center',
                      width: small || textBackground ? '50%' : '100%',
                    }}
                    fluid={image.localFile.childImageSharp.fluid}
                  />
                )}
                <Box
                  align="start"
                  alignSelf="stretch"
                  basis="1/2"
                  justify="between"
                  background={textBackground}
                  pad={{ top: small ? 'large' : 'large', horizontal: small ? 'large' : 'xlarge' }}
                  style={{ position: 'relative' }}
                >
                  <Box>
                    {headline && (
                      <Heading level="1" margin="none">
                        {headline}
                      </Heading>
                    )}
                    {body && (
                      <ThemeContext.Extend
                        value={{
                          paragraph: { medium: { maxWidth: '460px' } },
                        }}
                      >
                       <RichText
                         Link={Link} 
                         body={body}
                        />
                      </ThemeContext.Extend>
                    )}
                  </Box>
                  
                </Box>
                
              </HeroContainer>
            </HeroOuterContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
