import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Box, Heading } from 'grommet';
import RichText from '../RichText';

const FeatureCardImg = styled(Img)`
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.1);
`;

export default function({ Link, body, headline, image, ...rest }) {
  return (
    <Box {...rest}>
      <Box fill="horizontal">
        {image && image.localFile && (
          <FeatureCardImg fluid={image.localFile.childImageSharp.fluid} />
        )}
      </Box>
      <Box flex="grow">
        {headline && (
          <Heading level="3" margin={{ bottom: 'none' }}>
            {headline}
          </Heading>
        )}
        {body && <RichText body={body} />}
      </Box>
    </Box>
  );
}
