import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';

export default function HeroInteriorAltCta({ buttonHeadline, buttonUrl, buttonText, Link }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = ['small', 'hero'].includes(size);

        return (
          <Box
            background="accent-1"
            pad="large"
            width={small ? '100%' : 'medium'}
            margin={{ top: small ? 'none' : 'large' , bottom: small ? 'large' : '-72px' }}
          >
            {buttonHeadline && (
              <Heading level="4" margin={{ top: 'none' }} size={small ? '25px' : '28px'}>
                {buttonHeadline}
              </Heading>
            )}
            {buttonUrl && buttonText && (
              <GatsbyButton
                Link={Link}
                color="hero-interior-alt-button"
                href={buttonUrl}
                label={buttonText}
                primary
              />
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
