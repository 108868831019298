import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Image, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';

const StyledImage = styled(Image)`
  max-width: 240px;
  max-height: 100px;
  width: 100%;
`;

export default function({ Link, background, headline, body, buttonText, buttonUrl, images }) {
  if (!images) return null;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <Box
            background={background}
            align="center"
            direction="row-responsive"
            pad={{
              vertical: hero || small ? 'large' : 'xlarge',
              horizontal: hero || small ? 'medium' : 'xlarge',
            }}
          >
            <Box basis="1/2" pad={{ horizontal: 'medium' }}>
              {headline && (
                <Heading level="2" margin="none">
                  {headline}
                </Heading>
              )}
              {body && <RichText body={body} />}
              {buttonUrl && buttonText && (
                <GatsbyButton
                  Link={Link}
                  href={buttonUrl}
                  label={buttonText}
                  color="accent-1"
                  alignSelf={small ? 'stretch' : 'start'}
                  primary
                />
              )}
            </Box>
            <Box direction="row" justify="around" pad="small" flex wrap>
              {images &&
                images.map((src) => (
                  <Box basis={images.length > 3 ? '1/2' : '1/2'} align="center">
                    <StyledImage src={src} fit="contain" />
                  </Box>
                ))}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
