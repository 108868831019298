import React from 'react';
import styled from 'styled-components';
import { Box, Button, Menu } from 'grommet';
import HeaderAltNavAnchor from './HeaderAltNavAnchor';
import GatsbyButton from '../GatsbyButton';

const DropdownBox = styled(Box)`
align-self: center;
font-weight: normal;
font-size: 16px !important;
line-height: 24px;
text-align: left;
padding: 0 10px;

&:hover {
  background-color: none;
  var(--accent-2);
}
`;

const MenuContainer = styled(Menu)`
&:hover {
  background-color: none;
  color: var(--accent-2);
}
`;

export default function({ Link, loginLink, menuLinks, mobile, primaryLink, href, ...rest }) {
  return (
    <>
      <Box
        as="nav"
        alignSelf="stretch"
        direction={mobile ? 'column' : 'row'}
        pad={mobile ? 'large' : null}
        {...rest}
      >
        
        {mobile ? '' :
          <MenuContainer
            label="Solutions"
            dropProps={{ align: { top: "bottom", left: "left" }, margin: {top: '40px'}}}
            dropBackground={{"color": "neutral-1", "opacity": true}}
            style={{fontWeight: 'normal', marginRight: '20px'}}
            size={mobile ? 'medium' : 'small'}
            plain
            items={[
              { label: <DropdownBox>For Students &amp; Faculty</DropdownBox>, href: '/for-students-and-faculty' },
              { label: <DropdownBox>For Corporate &amp; Media</DropdownBox>, href: '/for-corporate-media-companies' },
              { label: <DropdownBox>For Retail &amp; Commercial Banks</DropdownBox>, href: '/for-retail-commercial-banking' },
              { label: <DropdownBox>For Utilities, Logistics &amp; Construction</DropdownBox>, href: '/for-utilities-logistics-construction' }
            ]}
          />
        }
        <HeaderAltNavAnchor
        Link={Link}
        activeClassName="active"
        href='/how-it-works'
        last={menuLinks.index >= menuLinks.length - 1}
        mobile={mobile}
        size={mobile ? 'medium' : 'small'}
        style={mobile ? '' : {marginRight: '20px'}}
      > 
        How It Works
      </HeaderAltNavAnchor>
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/resources'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'medium' : 'small'}
          style={mobile ? '' : {marginLeft: '10px',marginRight: '30px'}}
          partiallyActive
        > 
          Resources
        </HeaderAltNavAnchor>
        <HeaderAltNavAnchor
          Link={Link}
          activeClassName="active"
          href='/news'
          last={menuLinks.index >= menuLinks.length - 1}
          mobile={mobile}
          size={mobile ? 'medium' : 'small'}
          partiallyActive
        > 
          In the News
        </HeaderAltNavAnchor>
      </Box>
      
      <Box
        direction={mobile ? 'column' : 'row'}
        gap="small"
        pad={mobile ? { horizontal: 'medium', bottom: '120px' } : { vertical: 'small' }}
      >
        {loginLink && loginLink.name && loginLink.url && (
          <Button
            color="accent-1"
            label={loginLink.name}
            href={loginLink.url}
            rel="noopener noreferrer"
            target="_blank"
          />
        )}
        {primaryLink && primaryLink.name && primaryLink.url && (
          <GatsbyButton
            Link={Link}
            color="accent-1"
            label={primaryLink.name}
            href={primaryLink.url}
            primary
          />
        )}
      </Box>
    </>
  );
}
