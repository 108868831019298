function buttonSmallStyle({ small }) {
  return !small
    ? ''
    : `
      font-size: 14px;
      padding: 4px 16px;`;
}

function uppercaseStyle({ uppercase }) {
  return !uppercase ? '' : 'text-transform: uppercase;';
}

export default {
  global: {
    colors: {
      brand: '#FFC940',
      focus: '#D04A02',
      selected: 'accent-4',
      border: {
        dark: '#FFFFFF',
        light: '#212121',
      },
      icon: {
        dark: '#FFFFFF',
        light: '#212121',
      },
      text: {
        dark: '#FFFFFF  !important',
        light: '#212121 !important',
      },
      'accent-1': '#EA8C00',
      'accent-2': '#D04A02',
      'accent-3': '#279D8F',
      'accent-4': '#EFC07A',
      'dark-1': '#212121',
      'dark-2': '#2D2D2D',
      'light-5': '#B0B0B2',
      'light-6': '#DEDEDE',
      'light-7': '#F5F5F5',
      'hero-interior-button': 'accent-1',
      'hero-interior-alt-button': 'dark-1',
      'mobile-nav-rule': '#FFC940',
    },
    breakpoints: {
      hero: {
        value: 1200,
      },
    },
    control: {
      border: {
        radius: 'none',
      },
    },
    edgeSize: {
      xlarge: '72px',
    },
    font: {
      family: 'PwC Helvetica Neue, sans-serif',
    },
    input: {
      weight: 400,
    },
    hover: {
      background: {
        color: 'accent-2',
      },
      color: {
        dark: 'accent-1',
        light: 'accent-1',
      },
    },
  },
  accordion: {
    border: {
      side: 'none',
      color: 'none',
    },
  },
  anchor: {
    color: {
      dark: 'accent-1',
      light: 'accent-2',
    },
    fontWeight: 400,
    textDecoration: 'underline',
    hover: {
      textDecoration: 'none',
    },
  },
  button: {
    border: {
      radius: '0',
      width: '1px',
      color: {
        dark: 'white',
        light: 'accent-2',
      },
    },
    padding: {
      vertical: '10px',
      horizontal: '20px',
    },
    primary: {
      color: {
        dark: 'white',
        light: 'accent-2',
      },
    },
    focusIndicator: 'false',
    extend: (props) => {
      return `
        ${buttonSmallStyle(props)}
        ${uppercaseStyle(props)}

        min-width: ${props.minWidth || 'auto'};
        text-align: center;
        font-weight: bold;

        & svg {
          fill: currentColor;
          stroke: currentColor;
        }
      `;
    },
  },
  // menu: {
  //   item: {
  //     color: '#000000',
  //     style: { 
  //       fontFamily: 'Arial', 
  //       fontWeight: 'bold', 
  //       backgroundColor: '#d04A02',
  //     },
  //   }
  // },
  radioButtonGroup: {
    container: {
      gap: 'medium',
    },
  },
  radioButton: {
    border: {
      color: '#BBBBBB',
      width: '2px',
    },
    size: '20px', // affects the size of the outer circle
  },
  formField: {
    border: {
      side: 'all',
    },
    label: {
      margin: {
        horizontal: 'none',
        vertical: 'xsmall',
      },
    },
    extend: () => {
      return `
        position: relative;

        & label {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 22px
        }

        & span {
          font-size: 14px;
          line-height: 1;
          margin: 0;
          position: absolute;
          top: 100%;
        }`;
    },
  },
  heading: {
    font: {
      family: 'PwC ITC Charter, serif',
    },
    weight: 400,
    level: {
      '1': {
        medium: {
          size: '54px',
          height: '55px',
        },
      },
      '2': {
        medium: {
          size: '40px',
          height: '50px',
        },
      },
      '3': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
        medium: {
          size: '28px',
          height: '38px',
        },
      },
      '4': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
        medium: {
          size: '22px',
          height: '28px',
        },
        small: {
          size: '14px',
          height: '22px',
        },
      },
      '5': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
        medium: {
          size: '18px',
          height: '24px',
        },
        small: {
          size: '16px',
          height: '20px',
        },
      },
      '6': {
        font: {
          family: 'PwC Helvetica Neue, sans-serif',
          weight: 'bold',
        },
      },
    },
    extend: (props) => {
      return `
        ${uppercaseStyle(props)};
      `;
    },
  },
  layer: {
    background: 'rgba(0, 0, 0, 0.8)',
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
  },
  paragraph: {
    xsmall: {
      size: '12px',
      height: '18px'
    },
    petite: {
      size: '14px',
      height: '24px'
    },
    small: {
      size: '16px',
      height: '28px',
    },
    medium: {
      size: '18px',
      height: '32px',
    },
    extend: () => {
      return `
      margin-top: 0px
      `;
    }
  },
  select: {
    icons: {
      color: {
        dark: 'light-1',
        light: 'dark-1',
      },
    },
    options: {
      text: {
        color: 'dark-1',
        size: '16px',
        weight: 'normal',
      },
    },
  },
  text: {
    small: {
      size: '16px',
      height: '32px',
    },
    medium: {
      size: '18px',
      height: '28px',
    },
    large: {
      size: '20px',
      height: '28px',
    },
  },
};
