import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Box, Heading } from 'grommet';
import GatsbyAnchor from '../GatsbyAnchor';

const StoryTitleLink = styled(GatsbyAnchor)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
`;

export default function({ Link, headline, image, linkUrl, linkText, ...rest }) {
  return (
    <Box
      background="white"
      border={{ color: 'light-6' }}
      round={{ size: '5px' }}
      alignSelf="stretch"
      fill="horizontal"
      {...rest}
    >
      <Box fill="horizontal" overflow="hidden">
        {image && image.localFile && (
          <StoryTitleLink Link={Link} href={linkUrl}>
            <Img fluid={image.localFile.childImageSharp.fluid} />
          </StoryTitleLink>
        )}
      </Box>
      <Box flex="grow" pad="medium">
        {headline && (
          <Heading level="3" margin={{ top: 'none', bottom: 'small' }}>
            <StoryTitleLink Link={Link} href={linkUrl}>
              {headline}
            </StoryTitleLink>
          </Heading>
        )}
        {linkUrl && linkText && (
          <GatsbyAnchor Link={Link} href={linkUrl}>
            {linkText}
          </GatsbyAnchor>
        )}
      </Box>
    </Box>
  );
}
