import React from 'react';
import styled from 'styled-components';
import { Paragraph, Heading, ThemeContext } from 'grommet';

const FaqBox = styled.div`
  break-inside: avoid;
`;

export default function({ question, answer }) {
  return (
    <FaqBox>
      <ThemeContext.Extend
        value={{
          paragraph: { medium: { maxWidth: 'auto' } },
          heading: { level: { 4: { medium: { maxWidth: 'auto' } } } },
        }}
      >
        <Heading level="4" margin="none">
          {question}
        </Heading>
        <Paragraph margin={{ bottom: 'large' }}>{answer}</Paragraph>
      </ThemeContext.Extend>
    </FaqBox>
  );
}
