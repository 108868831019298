import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Box, Form, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import { Close } from 'grommet-icons';
import FormEmbedFields from './FormEmbedFields';
import FormEmbedSuccess from './FormEmbedSuccess';
import submitForm from './submitForm';

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`;

const FormContainer = styled(Box)`
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

export default function({
  borderColor,
  buttonText,
  fields,
  headline,
  hiddenFields,
  id,
  mailSendEndpoint,
  slug,
  submitType,
  success,
}) {
  const [submitted, setSubmitted] = useState(false);

  return (
          <ThemeContext.Extend>
            <ResponsiveContext.Consumer>
              {(size) => (
                <>
                  <FormContainer
                    background="white"
                    border={{ color: borderColor || 'brand', size: 'large' }}
                    width="large"
                    overflow={{ vertical: 'auto', horizontal: 'hidden' }}
                  >
                    {submitted ? (
                      <FormEmbedSuccess setSubmitted={setSubmitted} {...success} />
                    ) : (
                      <Box pad="large" flex={false}>
                        {headline && (
                          <Heading level="2" margin={{ top: 'none' }} textAlign="center">
                            {headline}
                          </Heading>
                        )}
                        <Form
                          noValidate
                          id={id}
                          onSubmit={(e) => {
                            if (window.dataLayer) window.dataLayer.push({ event: 'form-submit' });
                            submitForm({
                              data: e.value,
                              hiddenFields,
                              headline,
                              mailSendEndpoint,
                              submitType,
                            });
                            setSubmitted(true);
                          }}
                        >
                          <FormEmbedFields fields={fields} />
                          <Box align="center" justify="center" margin={{ top: 'large' }}>
                            <Button
                              type="submit"
                              label={buttonText || 'Submit'}
                              minWidth="200px"
                              primary
                            />
                          </Box>
                        </Form>
                      </Box>
                    )}
                  </FormContainer>

                  <CloseButton
                    color="dark-1"
                    style={{ padding: size === 'small' ? '12px' : '24px' }}
                    icon={<Close />}
                    onClick={() => {
                      setSubmitted(false);
                    }}
                    plain
                  />
                  <iframe title="hidden-form" name="hidden-form" style={{ display: 'none' }} />
              </>
              )}
            </ResponsiveContext.Consumer>
          </ThemeContext.Extend>
  );
}
