import React from 'react';
import { Helmet } from 'react-helmet';

export default function({ data, title, image, description = '', lang = 'en' }) {
  const titleTemplate = `%s : ${data.site.siteMetadata.title}`;
  const url = process.env.GATSBY_ROOT_URL || '';

  return (
    <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={titleTemplate}>
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={url + image} />}
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={url + image} />}
      <meta name="twitter:card" content="summary" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={url + image} />}
      <meta name="google-site-verification" content="3nPz-OsJ1L4QsD3lC23qLcbYxMhuOtZXndAmac-A1cY" />
      <script dangerouslySetInnerHTML={{
          __html: `var _elqQ = _elqQ || [];
          _elqQ.push(['elqSetSiteId', '1017189481']);
          _elqQ.push(['elqUseFirstPartyCookie', 'elqjourney.pwc.com']);
          _elqQ.push(['elqTrackPageView', window.location.href]);

          (function () {
              function async_load() {
                  var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true;
                  s.src = '//img06.en25.com/i/elqCfg.min.js';
                  var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
              }
              async_load();
          })();
          `,
        }}
        />
    </Helmet>
  );
}
