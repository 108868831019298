import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, ThemeContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';
//import { formatPrice } from '../../utils';

const Container = styled(Box)`
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.15);
`;

const FootnoteText = styled(Text)`
  font-style: italic;
`;

const Onboarding = styled(Box)`
  text-align: center;
  & i {
    display: block
  }
`;

const CtaContainer = styled(Box)`
  text-align: center;
  min-height: 100px;

  & p:first-child {
    margin: 0;
  }

  & a {
    font-weight: bold;
  }
`;

const Features = styled.ul`
  flex: 1 1 auto;
  list-style: none;
  padding: 0;
`;

const Feature = styled.li`
  margin-bottom: 28px;
  position: relative;
  padding-left: 40px;
  &:last-child {
    margin-bottom: 10px;
  }
  svg {
    position: absolute;
    left: 0px;
    top: 2px;
  }
`;

export default function({
  Link,
  features,
  featuresHeadline,
  fees,
  feesHeadline,
  first,
  headline,
  cardSummary,
  cardSummaryPlus,
  onboarding,
  prices,
  ctaText,
  primaryCta,
  secondaryCta
}) {
  return (
    <Container
      round={{ size: '10px' }}
      basis="1/3"
      align="center"
      first={first}
      background={first ? 'dark-2' : 'white'}
      pad={{horizontal:'large', vertical:'large'}}
    >
      <Heading level="4" margin={{ top: 'none' }} textAlign="center">
        {headline}
      </Heading>
      <Box justify="center" fill="horizontal" height={{"min": "180px"}}>
      {cardSummary && (
          <Box justify="center" fill="horizontal">
            <Text margin={{ top: 'none' }} textAlign="center" weight="bold">
                {cardSummary}
            </Text>
            <Text textAlign="center" color="#FFB600" weight="bold" margin="small" >PLUS</Text>
          </Box>
      )}
        <Text margin={{ top: 'none' }} textAlign="center">
          {cardSummaryPlus}
        </Text>
      </Box>
      {prices && (
          <Box
            direction="row-responsive" 
            justify="center" 
            fill="horizontal">
            {prices.map(
              ({ eyebrow, frequency, price, footnote }, index) =>
                price && (
                  <>
                    {index % 2 !== 0 && (
                      <Box alignSelf="center" justify="center" margin={{ horizontal: 'large', vertical: 'medium' }} width="100%">
                        <svg width="57" height="57">
                          <path
                            fill="#FFB600"
                            d="M34.283 22.417H56.35v11.866H34.283V56.35H22.417V34.283H.35V22.417h22.067V.35h11.866z"
                          />
                        </svg>
                      </Box>
                    )}
                    <Box 
                      align="center"
                      border={{
                        color: 'dark-3',
                        side: 'all',
                      }}
                      margin={{ top: 'medium' }}
                      pad={{ horizontal: 'large', vertical: 'large' }}
                      width="100%"
                    >
                      {eyebrow && <Text weight="bold">{eyebrow}</Text>}
                      {price && (
                        <Text weight="bold" size="52px" textAlign="center">
                          {(price)}
                        </Text>
                      )}
                      {frequency && <Text weight="bold" textAlign="center" >{frequency}</Text>}
                      {footnote && <FootnoteText>{footnote}</FootnoteText>}
                    </Box>
                  </>
                ),
            )}
          </Box>
      )}

      {onboarding && (
        <Onboarding
          margin={{ top: 'medium' }}
          pad={{ horizontal: 'medium' }}
        >
          <RichText body={onboarding} />
        </Onboarding>
      )}
      {feesHeadline && (
         <Features
            id="fee"
          >
            <Heading level="4" size="small" uppercase>
              {feesHeadline}
            </Heading>
          {fees.map((fee) => (
            <Feature>
              <svg width="26" height="20">
                <path
                  fill="#FFB600"
                  d="M 22.58,-0 L 9.23,13.22 3.42,7.46 0,10.85 9.23,20 26,3.39 22.58,-0 Z M 22.58,-0"
                />
              </svg>
              {fee}
            </Feature>
          ))}
        </Features>
      )}
  
      <Features
        id="features"
      >
        {featuresHeadline && (
          <Heading level="4" size="small" uppercase>
            {featuresHeadline}
          </Heading>
        )}
        {features.map((feature) => (
          <Feature>
            <svg width="26" height="20">
              <path
                fill="#FFB600"
                d="M 22.58,-0 L 9.23,13.22 3.42,7.46 0,10.85 9.23,20 26,3.39 22.58,-0 Z M 22.58,-0"
              />
            </svg>
            {feature}
          </Feature>
        ))}
      </Features>

      {(ctaText || primaryCta || secondaryCta) && (
        <CtaContainer
          border={{
            color: 'dark-4',
            side: 'top',
          }}
          fill="horizontal"
          align="center"
          justify="center"
          pad={{ top: 'large' }}
        >
          {ctaText && <RichText Link={Link} body={ctaText} />}
          {(primaryCta || secondaryCta) && (
            <Box direction="row-responsive" fill="horizontal" justify="center" gap="medium">
              {primaryCta && (
                <Box basis="100%" pad={{ horizontal: 'medium' }}>
                  <ThemeContext.Extend value={{ button: { padding: { horizontal: '0' } } }}>
                    <GatsbyButton
                      Link={Link}
                      href={primaryCta.buttonUrl}
                      label={primaryCta.buttonText}
                      color="accent-1"
                      primary
                    />
                  </ThemeContext.Extend>
                </Box>
              )}
              {secondaryCta && (
                <Box basis="1/2">
                  <ThemeContext.Extend value={{ button: { padding: { horizontal: '0' } } }}>
                    <GatsbyButton
                      Link={Link}
                      href={secondaryCta.buttonUrl}
                      label={secondaryCta.buttonText}
                      width="medium"
                      color="accent-1"
                    />
                  </ThemeContext.Extend>
                </Box>
              )}
            </Box>
          )}
        </CtaContainer>
      )}
    </Container>
  );
}
