import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Box, Form, Heading, Layer, ResponsiveContext, ThemeContext } from 'grommet';
import { Close } from 'grommet-icons';
import FormModalContext from './FormModalContext';
import FormModalFields from './FormModalFields';
import FormModalSuccess from './FormModalSuccess';
import submitForm from './submitForm';

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`;

const FormContainer = styled(Box)`
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

export default function({
  borderColor,
  buttonText,
  fields,
  headline,
  subheadline,
  hiddenFields,
  id,
  mailSendEndpoint,
  slug,
  submitType,
  success,
}) {
  const [submitted, setSubmitted] = useState(false);
  const utmValuesQs = () => {
    var n = window.location.toString().split("?");
    var fields = {};
    if (n.length > 1) {
        var r = n[1].split("&");
        var i;
        for (i in r) {
            var o = r[i].split("=");
            fields = { ...fields, [o[0]]: o[1]}
        }
    }
    return fields;
  };

  const utmValues = () => {
    let fields = {};

    if(localStorage.utm_medium) {
      fields.utm_medium = localStorage.utm_medium;
    }
    if(localStorage.utm_source) {
      fields.utm_source = localStorage.utm_source;
    }
    if(localStorage.utm_campaign) {
      fields.utm_campaign = localStorage.utm_campaign;
    }
    if(localStorage.utm_offer) {
      fields.utm_offer = localStorage.utm_offer;
    }
    if(localStorage.utm_term) {
      fields.utm_term = localStorage.utm_term;
    }
    if(localStorage.cid) {
      fields.cid = localStorage.cid;
    }
    return fields;
  }

  return (
    <FormModalContext.Consumer>
      {({ showModal, setShowModal }) =>
        (showModal && showModal.slug === slug || showModal === slug) && (
          <ThemeContext.Extend value={{ layer: { zIndex: 20 } }}>
            <ResponsiveContext.Consumer>
              {(size) => (
                <Layer
                  margin={size === 'small' ? 'none' : 'large'}
                  responsive={false}
                  onEsc={() => {
                    setShowModal(null);
                    setSubmitted(false);
                  }}
                  // onClickOutside={() => {
                  //   setShowModal(null);
                  //   setSubmitted(false);
                  // }}
                >
                  <FormContainer
                    background="white"
                    border={{ color: borderColor || 'brand', size: 'large' }}
                    width="large"
                    overflow={{ vertical: 'auto', horizontal: 'hidden' }}
                  >
                    {submitted ? (
                      showModal && showModal.customSuccess ? 
                      showModal.customSuccess() : 
                      <FormModalSuccess setSubmitted={setSubmitted} {...success} />
                    ) : (
                      <Box pad="large" flex={false}>
                        {headline && (
                          <Heading level="2" style={{maxWidth: '480px', margin: '0 auto'}} textAlign="center">
                            {headline}
                          </Heading>
                        )}
                        {subheadline && (
                          <Heading level="5" style={{maxWidth: '480px', fontWeight: 'normal', margin: '10px auto', textAlign: 'center'}}>
                            {subheadline}
                          </Heading>
                        )}
                        <Form
                          style={{ marginTop: '20px' }}
                          noValidate
                          id={slug}
                          messages={{required:"This is a required field" }}
                          onSubmit={(e) => {
                            const hiddenObj = Object.assign(utmValues(), (showModal.hiddenData || {}));
                            
                            if (window.dataLayer) window.dataLayer.push({ event: 'form-submit' });
                            submitForm({
                              data: { ...e.value, ...hiddenObj },
                              hiddenFields,
                              headline,
                              mailSendEndpoint,
                              submitType,
                            });
                            setSubmitted(true);
                          }}
                        >
                          <FormModalFields fields={fields} />
                          <Box align="center" justify="center" margin={{ top: 'large' }}>
                            <Button
                              type="submit"
                              label={buttonText || 'Submit'}
                              minWidth="200px"
                              primary
                            />
                          </Box>
                        </Form>
                      </Box>
                    )}
                  </FormContainer>

                  <CloseButton
                    color="dark-1"
                    style={{ padding: size === 'small' ? '12px' : '24px' }}
                    icon={<Close />}
                    onClick={() => {
                      setShowModal(null);
                      setSubmitted(false);
                    }}
                    plain
                  />
                  <iframe title="hidden-form" name="hidden-form" style={{ display: 'none' }} />
                </Layer>
              )}
            </ResponsiveContext.Consumer>
          </ThemeContext.Extend>
        )
      }
    </FormModalContext.Consumer>
  );
}