import React from 'react';
import styled from 'styled-components';
import { Box, Heading, ResponsiveContext } from 'grommet';
import { FacebookOption, Twitter, Mail, LinkedinOption } from 'grommet-icons';
import SocialCard from './SocialCard';

const socialData = [
  {
    name: 'Facebook',
    icon: <FacebookOption color="white" />,
    background: '#43619C',
  },
  {
    name: 'Twitter',
    icon: <Twitter color="white" />,
    background: '#00ACED',
  },
  {
    name: 'Linkedin',
    icon: <LinkedinOption color="white" />,
    background: '#0077B5',
  },
  {
    name: 'Mail',
    icon: <Mail />,
    background: '#2D2D2D',
  },
];

const ShareBox = styled(Box)`
  margin-right: 30px;
  max-width: 500px;
`;

const IconBox = styled(Box)`
  display: flex;
  padding-top: 13px;
`;

const ShareTitle = styled(Heading)`
  ${({ isSmall }) => (isSmall ? 'padding-top: 12px;' : '')}
  padding-right: 4%;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 22px;
  font-weight: bold;
`;

export default function SocialIcons({ title }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small' || size === 'hero';

        return (
          <ShareBox
            isSmall={isSmall}
            direction={isSmall ? 'row' : 'column'}
            justify="between"
            pad={{ vertical: 'medium' }}
            width={isSmall ? 'medium' : 'auto'}
          >
            <ShareTitle
              level="6"
              isSmall={isSmall}
              display={isSmall ? 'inline' : ''}
              alignSelf={isSmall ? 'center' : ''}
              uppercase="true"
              pad="none"
              margin="none"
            >
              share:
            </ShareTitle>
            <IconBox direction="row" justify="around" flex={{ grow: 1 }}>
              {socialData.map((card) => (
                <SocialCard {...card} title={title} />
              ))}
            </IconBox>
          </ShareBox>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
