import React, { useState } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import StoryCardAlt from './StoryCardAlt';

const pad = {
  small: 'large',
  hero: 'medium',
};

export default function({ Link, background, cards }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const expandedCards = expanded ? cards : cards.slice(0, 40);
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <Box background={background || 'white'} pad={small || hero ? pad[size] : 'large'}>
     
            <Box 
              pad={{top: '0'}}
              direction="row-responsive"
              wrap 
              width={{
                max: "1360px"
              }}
              margin="0 auto"
            >
              {expandedCards &&
                expandedCards.map((card, index) => (
                  <StoryCardAlt
                    Link={Link}
                    basis={small ? 'auto' : 'calc(33.33333% - 16px)'}
                    margin={{
                      bottom: small ? 'large' : 'medium',
                      right: small || (index + 1) % 3 === 0 ? 'none' : 'medium',
                    }}
                    {...card}
                  />
                ))}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
