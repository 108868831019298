import React from 'react';
import { Box, Heading, ThemeContext, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';

const theme = {
  global: { breakpoints: { small: { edgeSize: { medium: '24px' } } } },
  heading: { level: { '3': { medium: { maxWidth: '100%' } } } },
};

const StyledHeading = styled(Heading)`
  font-size: 22px;
  max-width: 100%;
`;

export default function({
  Link,
  background,
  headline,
  buttonText,
  buttonUrl,
  noRule,
  primary,
  buttonBackground,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size === 'small';

        return (
          <ThemeContext.Extend value={theme}>
            <Box background={background} pad={{ horizontal: 'xlarge' }}>
              <Box
                align="center"
                border={noRule ? null : 'top'}
                direction="row-responsive"
                gap="medium"
                pad={{ vertical: 'medium' }}
                justify="center"
              >
                {headline && (
                  <StyledHeading textAlign={isSmall ? 'center' : ''} level="3">
                    {headline}
                  </StyledHeading>
                )}
                {buttonUrl && buttonText && (
                  <GatsbyButton
                    Link={Link}
                    href={buttonUrl}
                    label={buttonText}
                    minWidth="200px"
                    background={buttonBackground}
                    primary={primary}
                    margin={isSmall ? { bottom: '30px' } : ''}
                  />
                )}
              </Box>
            </Box>
          </ThemeContext.Extend>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
