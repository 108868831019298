import React from 'react';
import Hero from '../components/HeroInteriorAlt';
import HighlightListAlt from '../components/HighlightListAlt';
import LogoGridProduct from '../components/LogoGridProduct';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, highlights, logos } = doc;

  return (
    <>
      <Hero Link={Link} {...hero} />
      <HighlightListAlt Link={Link} {...highlights} />
      <LogoGridProduct Link={Link} {...logos} />
    </>
  );
}
