export { fransuiteTheme, insightsofficerTheme, checkinTheme, taxverseTheme, workforceTheme } from './grommet/Theme';
export { default as DetailsFransuite } from './templates/DetailsFransuite';
export { default as DetailsInsightsOfficer } from './templates/DetailsInsightsOfficer';
export { default as DetailsAltInsightsOfficer } from './templates/DetailsAltInsightsOfficer';
export { default as DetailsCheckIn } from './templates/DetailsCheckIn';
export { default as DetailsAltCheckIn } from './templates/DetailsAltCheckIn';
export { default as DetailsTaxVerse } from './templates/DetailsTaxVerse';
export { default as DetailsAccountantsTaxVerse } from './templates/DetailsAccountantsTaxVerse';
export { default as DetailsWorkforce } from './templates/DetailsWorkforce';
export { default as DetailsSolutionsWorkforce } from './templates/DetailsSolutionsWorkforce';
export { default as FastcastTaxVerse } from './templates/FastcastTaxVerse';
export { default as Footer } from './components/Footer';
export { default as FormModal, FormModalContext, FormModalSuccess, useShowModal } from './components/FormModal';
export { default as FormEmbed, FormEmbedContext, useShowEmbed } from './components/FormEmbed';
export { default as GetStartedWorkforce } from './templates/GetStartedWorkforce';
export { default as SolutionsWorkforce } from './templates/SolutionsWorkforce';
export { default as PlatformWorkforce } from './templates/PlatformWorkforce';
export { default as Header } from './components/Header';
export { default as Header2 } from './components/Header2';
export { default as HeaderCalc } from './components/HeaderCalc';
export { default as HeaderApp } from './components/HeaderApp';
export { default as HeaderAlt } from './components/HeaderAlt';
export { default as HeaderCheckIn } from './components/HeaderCheckIn';
export { default as Home } from './templates/Home';
export { default as HomeInsightsOfficer } from './templates/HomeInsightsOfficer';
export { default as HomeCheckIn } from './templates/HomeCheckIn';
export { default as HomeTaxVerse } from './templates/HomeTaxVerse';
export { default as HomeWorkforce } from './templates/HomeWorkforce';
export { default as Layout } from './templates/Layout';
export { default as NotFound } from './templates/NotFound';
export { default as PricingTaxVerse } from './templates/PricingTaxVerse';
export { default as PricingInsightsOfficerAlt } from './templates/PricingInsightsOfficerAlt';
export { default as WhatsIncludedBC } from './templates/WhatsIncludedBC';
export { default as PricingCheckInAlt } from './templates/PricingCheckInAlt';
export { default as PricingTaxVerseAlt } from './templates/PricingTaxVerseAlt';
export { default as SEO } from './templates/SEO';
export { default as StoriesFransuite } from './templates/StoriesFransuite';
export { default as StoriesInsightsOfficer } from './templates/StoriesInsightsOfficer';
export { default as StoriesCheckIn } from './templates/StoriesCheckIn';
export { default as StoriesTaxVerse } from './templates/StoriesTaxVerse';
export { default as StoriesWorkforce } from './templates/StoriesWorkforce';
export { default as ResourcesTaxVerse } from './templates/ResourcesTaxVerse';
export { default as ResourcesInsightsOfficer } from './templates/ResourcesInsightsOfficer';
export { default as ResourcesCheckIn } from './templates/ResourcesCheckIn';
export { default as Story } from './templates/Story';
export { default as StoryContext } from './templates/StoryContext';
export { linkResolver, getParams } from './utils';
