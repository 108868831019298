import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Box, ResponsiveContext, Heading, Text } from 'grommet';

const Container = styled(Box).attrs(({ background }) => ({
  background,
  pad: { vertical: 'large', horizontal: 'large' },
  direction: 'row',
  justify: 'center'
}))``;

const InnerContainer = styled(Box)`
  max-width: 1370px;
  width: 100%;
`;

const Title = styled(Heading).attrs({
  level: 1,
  margin: {
    vertical: 'medium',
  }
})``;

const Meta = styled(Text)`
  font-style: italic;
`;

export default function StoryHeader({ categoryName, headline, authorName, date, background }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size !== 'medium' && size !== 'large';
        return (
          <>
            <Container background={background}>
              <InnerContainer>
                {categoryName && (
                  <Heading level="4" size="14px" margin="0" uppercase>
                    {categoryName}
                  </Heading>
                )}
                <Box
                  width={{
                    max: '920px'
                  }}
                >
                  <Title>{headline}</Title>
                </Box>
                <Box>
                  <Meta>
                    {authorName && <>By {authorName} on </>}
                    {date && format(new Date(date), 'MMMM d, yyyy')}
                  </Meta>
                </Box>
                </InnerContainer>
            </Container>
            </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
