import React, { useState } from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import CtaBanner from '../components/CtaBanner';
import styled from 'styled-components';
import HeroMain from '../components/HeroMain';
import StoryListAlt from '../components/StoryListAlt';
import GatsbyAnchor from '../components/GatsbyAnchor';

const FeatContainer = styled(Box)`
  background: white;
  border: solid 1px #D2D2D2;
  border-radius: 5px;
  // width: calc(calc(32% - 16px));
  padding: 48px 24px;
  text-align: center;
  margin-bottom: 20px;
`;
const FeatContainerLink = styled(GatsbyAnchor)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  outline-color: none !important;
  border-color: none;
`;

export default function({ doc, Link }) {
  const [stateTags, setTags] = useState({ 'All Content': true });
  if (!doc) return null;

  const { hero, categories, cta } = doc;
  const handleTag = (e, tagValue) => {
    const target = e.target;
    setTags((tags) => {
      return {
        ...tags,
        [tagValue]: target.checked
      }
    })
  };
  const pad = {
    small: 'large',
    hero: 'medium',
  };

  return (
    <>
      <HeroMain Link={Link} {...hero} />
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = size === 'small';
          const hero = size === 'hero';

          return (
            <Box
              pad={{top: 'large'}}
            >
              <Heading
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                Featured Resources
              </Heading>
              <Box 
                direction="row-responsive"
                justify="between"
                wrap 
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
                pad={small || hero ? pad[size] : 'large'}
              >
                <FeatContainer
                  basis={small ? 'auto' : 'calc(32% - 16px)'}
                >
                  <FeatContainerLink href={"/healthcheck"} target={"_blank"}>
                    <img width="62px" src="https://images.ctfassets.net/l07wyut35yzl/6zV7PAyaImLLU0fYbW6ms6/aec8e5cbafb25038c2e6e4aae4bced75/io-icon-healthcheck2.svg" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >Quiz: Check the accounting health of your business
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Learn <b>how strong</b> your bookkeeping is&mdash;and what you can do to get in shape.</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Check your score</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://info.bookkeepingconnect.pwc.com/valuecreation-lp"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >eBook: How can you better leverage your accounting function?
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Learn how you can ultimately leverage your books to create value for your company.</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
                <FeatContainer basis={small ? 'auto' : 'calc(32% - 16px)'}>
                  <FeatContainerLink href={"https://info.bookkeepingconnect.pwc.com/embracing-tech-to-save-time-money-lp"} target={"_blank"}>
                  <img width="62px" src="https://images.ctfassets.net/hql8txguu3eo/3RSIa9LHosUVqlcOvGopRK/0fe2a86f66aa6f7fe1691b3ef6fc5e18/tv-guide-icon.png" />
                    <Heading
                      margin={{ vertical: 'medium', horizontal: 'medium' }}
                      level="4"
                      alignSelf="center"
                      textAlign="center"
                    >eBook: Embracing tech to save time and money
                    </Heading>
                    <Paragraph
                      margin={{ top: '0', horizontal: 'medium' }}
                    >Read how innovative companies are taking command of their accounting and administrative processes.</Paragraph>
                    <Paragraph
                      margin={{ top: '20px' }}
                      style={{
                          color: '#D04A02'
                      }}
                    ><u>Download eBook</u></Paragraph>
                  </FeatContainerLink>
                </FeatContainer>
              </Box>
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
      {categories &&
        categories.map((stories, index) => {
          const cardsFiltered = stories.cards.filter((card) => {
            const shouldShow = stateTags['All Content']
              ? true
              : card.tags === null
              ? false
              : card.tags &&
                card.tags.reduce((acc, curTag) => {
                  return acc || stateTags[curTag] || false;
                }, false);
            return shouldShow;
          });
          const storiesFiltered = { ...stories, cards: cardsFiltered };

          console.log(cardsFiltered);
          const background = (index + 1) % 2 === 0 ? 'light-6' : 'white';
          return (
            <>
              <Box 
                pad={{top: 'large'}}
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
              >
              <Heading
                margin={{ top: 'medium', bottom: 'medium' }}
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                Webinars, eBooks, and Blog Posts
              </Heading>
              </Box>
  
              <Box 
                pad={{bottom: 'xlarge'}}
                direction="row-responsive"
                justify="evenly"
                width={{
                  max: "1360px"
                }}
                margin="0 auto"
              >
                <Box 
                  direction="row"
                  justify="between"
                >
                <StoryListAlt Link={Link} background={background} {...storiesFiltered} />
                </Box>
              </Box>
              {cta && cta.headline && <CtaBanner Link={Link} background={background} {...cta} />}
            </>
          );
        })}
    </>
  );
}
