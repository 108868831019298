import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Drop, Image, ResponsiveContext } from 'grommet';
import { Close, Menu } from 'grommet-icons';
import GatsbyAnchor from '../GatsbyAnchor';
import HeaderCalcNav from './HeaderCalcNav';

const HeaderContainer = styled.header`
  position: inherit;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #D2D2D2;
`;

const HeaderDrop = styled(Drop)`
  -webkit-overflow-scrolling: touch;
  display: block;
  height: 100%;
  max-height: 100% !important;
  overflow-y: auto;
`;

export default function({
  Link,
  background,
  loginLink,
  logoUrl,
  menuLinks,
  mobileBackground,
  primaryLink,
  ...rest
}) {
  const headerRef = useRef(null);
  const [show, setShow] = useState(false);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const mobile = ['small', 'hero'].includes(size);
        const medium = ['medium', 'medium'].includes(size);

        return (
          <HeaderContainer id="site-header">
            <Box
              align="center"
              background={background}
              direction="row"
              justify="between"
              pad={{
                right: '15px'
              }}
              ref={headerRef}
              border={
                !mobile
                  ? null
                  : {
                      color: 'light-6',
                      side: 'bottom',
                    }
              }
              {...rest}
            >
              {logoUrl && (
                <GatsbyAnchor 
                  Link={Link} href="/" 
                  onClick={() => setShow(false)}
                  style={
                    mobile
                      ? {
                        maxWidth: 80 + '%'
                      }
                      : medium ? {
                        maxWidth: 320 + 'px'
                      } : {
                        maxWidth: 440 + 'px'
                      }
                  }
                  >
                  <Box>
                    <Image 
                      src={logoUrl}
                      style={
                        mobile
                          ? {
                            height: 48 + 'px'
                          }
                          : {
                            height: 74 + 'px'
                          }
                      }
                       />
                  </Box>
                </GatsbyAnchor>
              )}
              
              {mobile ? (
                <>
                  <Button icon={show ? <Close /> : <Menu />} plain onClick={() => setShow(!show)} />
                  {show && (
                    <HeaderDrop
                      align={{ top: 'bottom', right: 'right' }}
                      onClickOutside={() => setShow(false)}
                      onClick={() => setShow(false)}
                      onEsc={() => setShow(false)}
                      target={headerRef.current}
                      background={mobileBackground || background}
                      plain
                    >
                      <HeaderCalcNav
                        Link={Link}
                        loginLink={loginLink}
                        menuLinks={menuLinks}
                        primaryLink={primaryLink}
                        mobile
                      />
                    </HeaderDrop>
                  )}
                </>
              ) : (
                <HeaderCalcNav
                  Link={Link}
                  loginLink={loginLink}
                  menuLinks={menuLinks}
                  primaryLink={primaryLink}
                  alignContent="end"
                />
              )}
            </Box>
          </HeaderContainer>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
