import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import FaqItem from './FaqItem';

const FaqItems = styled.div`
  column-gap: 3rem;
  display: block;
  ${({ small }) => (small ? 'column-count: 1' : 'column-count: 2')}
`;

export default function({ headline, pricingFaQs }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';

        return (
          <Box align="center" pad={{ horizontal: 'xlarge', vertical: 'large' }}>
            <Heading level="2" margin={{ bottom: 'xlarge' }}>
              {headline}
            </Heading>
            <FaqItems pad="small" small={small}>
              {pricingFaQs.length && pricingFaQs.map((card) => <FaqItem {...card} />)}
            </FaqItems>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
