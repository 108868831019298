import React from 'react';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';
import RichText from '../RichText';

const HeroContainer = styled(Box)`
  min-height: 66.667vh;
  position: relative;
  // max-width: 1560px;
  // margin: 0 auto;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 50vh;' : 'position: absolute !important; z-index: -1;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};

  @media (max-height: 640px) {
    max-height: 100vh;
  }
`;

export default function({ Link, headline, body, image, buttonText, buttonUrl, background }) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1200 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroContainer align="center" direction="row-responsive">
              {BgImg && (
                <BgImg
                  small={small}
                  objectFit="cover"
                  objectPosition={small ? 'center right' : 'center center'}
                  imgStyle={{
                    right: 0,
                    left: 'auto',
                    objectFit: 'cover',
                    objectPosition: small ? 'center right' : 'center center',
                    width: small ? '50%' : '100%',
                  }}
                  fluid={image.localFile.childImageSharp.fluid}
                />
              )}
              <Box
                align="start"
                basis="1/2"
                pad={small ? 'large' : 'xlarge'}
                justify="start"
                background={small ? background : ''}
              >
                {headline && (
                  <Heading level="1" margin="0 0 20px 0">
                    {headline}
                  </Heading>
                )}
                {body && (
                  <ThemeContext.Extend
                    value={{
                      paragraph: { medium: { maxWidth: '460px'}, large: { maxWidth: '540px' } },
                    }}
                  >
                    <RichText Link={Link} body={body} />
                  </ThemeContext.Extend>
                )}
                {buttonUrl && buttonText && (
                  <GatsbyButton Link={Link} href={buttonUrl} style={{marginTop: '10px'}} label={buttonText} primary />
                )}
              </Box>
            </HeroContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
