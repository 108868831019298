import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Accordion, AccordionPanel, Box, Button, Heading, ThemeContext } from 'grommet';
import { Down, Up } from 'grommet-icons';
import RichText from '../RichText';

const StyledButton = styled(Button)`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;

  & svg {
    position: absolute;
    left: 1rem;
  }

  ${({ last }) =>
    !last &&
    `
  &:before {
    background-color: #ffc940;
    content: '';
    display: block;
    height: 1.5rem;
    left: calc(1.5rem - 1px);
    position: absolute;
    top: calc(100% + 1px);
    width: 10px;
    z-index: -1;
  }`}
`;

const StyledBox = styled(Box)`
  position: relative;

  ${({ last }) =>
    !last &&
    `
  &:before {
    background-color: #ffc940;
    content: '';
    display: block;
    height: 100%;
    left: 1.5rem;
    position: absolute;
    width: 10px;
  }`}
`;

function AccordionHeader({ label, active, last }) {
  return (
    <Box margin={{ bottom: '1.5rem' }}>
      <ThemeContext.Extend
        value={{
          button: {
            border: { color: 'dark-1', radius: 'none' },
            primary: { color: active ? 'dark-1' : 'white' },
          },
        }}
      >
        <StyledButton label={label} icon={active ? <Up /> : <Down />} last={last} primary />
      </ThemeContext.Extend>
    </Box>
  );
}

export default function({ cards }) {
  const [activeIndex, setActiveIndex] = useState([0]);

  return (
    <Accordion
      activeIndex={activeIndex}
      margin={{ bottom: 'large' }}
      onActive={(index) => setActiveIndex(index)}
      multiple
    >
      {cards &&
        cards.map(({ body, buttonText, headline, image }, index) => {
          const last = index === cards.length - 1;
          const active = activeIndex.includes(index);

          return (
            <AccordionPanel
              header={
                <AccordionHeader active={active} index={index} label={buttonText} last={last} />
              }
            >
              <StyledBox last={last}>
                <Box
                  animation={active ? 'fadeIn' : 'fadeOut'}
                  pad={{ right: 'large', left: '4rem' }}
                  flex={false}
                  align="center"
                >
                  <Box width="medium">
                    {image && <Img fluid={image.localFile.childImageSharp.fluid} />}
                    {headline && (
                      <Heading level="3" margin={{ bottom: 'none' }}>
                        {headline}
                      </Heading>
                    )}
                    {body && <RichText body={body} />}
                  </Box>
                </Box>
              </StyledBox>
            </AccordionPanel>
          );
        })}
    </Accordion>
  );
}
