import React from 'react';
import { Box, Button, Heading, Paragraph, ThemeContext } from 'grommet';
import { Checkmark } from 'grommet-icons';
import FormModalContext from './FormModalContext';

export default function({ headline, body, buttonText, setSubmitted }) {
  return (
    <Box align="center" pad="large" flex={false}>
      <ThemeContext.Extend
        value={{
          heading: { level: { '2': { medium: { maxWidth: '600px' } } } },
          paragraph: { medium: { maxWidth: '600px' } },
        }}
      >
        <Checkmark size="large" />
        {headline && (
          <Heading level="2" textAlign="center">
            {headline}
          </Heading>
        )}
        {body && (
          <Paragraph textAlign="center" margin={{ bottom: 'large' }}>
            {body}
          </Paragraph>
        )}
      </ThemeContext.Extend>
      <FormModalContext.Consumer>
        {({ setShowModal }) => (
          <Button
            label={buttonText}
            minWidth="200px"
            onClick={() => {
              setShowModal(null);
              setSubmitted(false);
            }}
          />
        )}
      </FormModalContext.Consumer>
    </Box>
  );
}
